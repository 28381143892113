import Vue from 'vue'
import Vuex from 'vuex'
//import BalanceBuilder from '../../src/BalanceBuilder.js'
import GamePlayDataServices from './../item-editor/services/GamePlayDataServices';
import GamePlayRatioApdater from "./../kpi-editor/classes/GamePlayRatioApdater";
import axios from "axios";
import SupportSkillsConstant from "./../constants/SupportSkillsConstants";

import NetworkUtils from '../../src/NetworkUtils.js';
import auth from "@/store/modules/auth";

import ItemImpactBonusModel from "../item-editor/classes/ItemImpactBonusModel";

Vue.use(Vuex);

// export default new Vuex.Store({
//     modules: {
//         cart,
//         products
//     },
//     strict: debug,
//     plugins: debug ? [createLogger()] : []
// })

/*
  case K_GAME_INTRO:
                    value = 1.02;
                    break;
                case K_GAME_TUNNEL_1:
                    value = 1.018;
                    break;
                case K_GAME_TUNNEL_2:
                    value = 1.019;
                    break;
                case K_GAME_TUNNEL_3:
                    value = 1.0195;
                    break;
                default:
                    value = 1.0195;
 */

export default new Vuex.Store({
    modules: {
      auth
    },
    state: {
        ui: {
          kpi : {
              heroBaseLevel : 1,
              bpm : 1
          }
        },

        ugsSetting:{
            environmentName: 'dev3',
            environmentId: '6095b066-2345-4eae-bcca-dc45a0133b13',
            environmentList: [
                {
                    'name':'dev3',
                    'id':'6095b066-2345-4eae-bcca-dc45a0133b13'
                },
            ],
            isAutoPublish: true,
        },

        templatePack:{
          packSelected: 'pack_main_1K',
          packList: [
              'pack_main_1K'
          ],
        },

        gameversion: [],
        configMinPlayerToShowTop3: 6,
        multipleSessionConfig: {
            heroBaseDmgPercent: 50,
            hpPercentPerElement: 10,
            dmgPercentPetElement: 10
        },

        automateCreateItemOptions: {
            isIncludeOptional: false,
            optionalRatioItem: 0.5,
            optionalRatioGoldItem: 2.0,
            isCustomRevive: false,
        },

        settings: {
            farmingSize: null
        },
        ratios: {
            farmingSize : null,
            hitPerSeconds: 5,
            hitBossPerSeconds : 10,
            dailyGameLevelPerDay: 30,
            dropCoinsFirstTerm: {
                dropCoinsGameFirstIntro: 1,
                dropCoinsFirstTunnel1: 1,
                dropCoinsFirstTunnel2: 1,
                dropCoinsFirstTunnel3: 1,
                dropCoinsFirstDefault: 1,
            },
            dropCoinsRatio: {
                dropCoinsGameIntro: 1.1,
                dropCoinsTunnel1: 1.2,
                dropCoinsTunnel2: 1.15,
                dropCoinsTunnel3: 1.15,
                dropCoinsDefault: 1.1,
            },
            costHeroLevelUpFirstTerm: {
                costHeroLevelUpFirstIntro: 1,
                costHeroLevelUpFirstTunnel1: 1,
                costHeroLevelUpFirstTunnel2: 1,
                costHeroLevelUpFirstTunnel3: 1,
                costHeroLevelUpFirstDefault: 1,
            },
            costHeroLevelUpRatio: {
                costHeroLevelUpIntro: 1.1,
                costHeroLevelUpTunnel1: 1.25,
                costHeroLevelUpTunnel2: 1.327,
                costHeroLevelUpTunnel3: 1.327,
                costHeroLevelUpDefault: 1.25,
            },
            costSupportLevelUpFirstTerm: {
                costSupportLevelUpFirstIntro: 5,
                costSupportLevelUpFirstTunnel1: 10,
                costSupportLevelUpFirstTunnel2: 30,
                costSupportLevelUpFirstTunnel3: 40,
                costSupportLevelUpFirstDefault: 50,
            },
            costSupportLevelUpRatio: {
                costSupportLevelUpIntro: 1.1,
                costSupportLevelUpTunnel1: 1.25,
                costSupportLevelUpTunnel2: 1.327,
                costSupportLevelUpTunnel3: 1.327,
                costSupportLevelUpDefault: 1.25,
            },
            baseDmgFirstTerm: {
                baseDmgFirstIntro: 1.1,
                baseDmgFirstTunnel1: 1.1,
                baseDmgFirstTunnel2: 1.019,
                baseDmgFirstTunnel3: 1.0195,
                baseDmgFirstDefault: 1.0195,
            },
            baseDmgRatio: {
                baseDmgIntro: 1.1,
                baseDmgTunnel1: 1.1,
                baseDmgTunnel2: 1.019,
                baseDmgTunnel3: 1.0195,
                baseDmgDefault: 1.0195,
            },
            dmgBossFirstTerm: {
                dmgBossFirstIntro: 1.1,
                dmgBossFirstTunnel1: 1.1,
                dmgBossFirstTunnel2: 1.19,
                dmgBossFirstTunnel3: 1.195,
                dmgBossFirstDefault: 1.195,
            },
            dmgBossCommonRatio: {
                dmgBossCommonIntro: 1.1,
                dmgBossCommonTunnel1: 1.1,
                dmgBossCommonTunnel2: 1.19,
                dmgBossCommonTunnel3: 1.195,
                dmgBossCommonDefault: 1.195,
            },
            supportDmgFirstTerm: {
                supportBaseDmgIntro: 1,
                supportBaseDmgTunnel1: 2,
                supportBaseDmgTunnel2: 3,
                supportBaseDmgTunnel3: 4,
                supportBaseDmgDefault: 5,
            },
            supportDmgRatio: {
                supportBaseDmgIntro: 1.1,
                supportBaseDmgTunnel1: 1.1,
                supportBaseDmgTunnel2: 1.019,
                supportBaseDmgTunnel3: 1.0195,
                supportBaseDmgDefault: 1.0195,
            },
            baseHPGhostFirstTerm: {
                baseHPGhostFirstIntro: 20,
                baseHPGhostFirstTunnel1: 20,
                baseHPGhostFirstTunnel2: 20,
                baseHPGhostFirstTunnel3: 20,
                baseHPGhostFirstDefault: 20,
            },
            baseHPGhostRatio: {
                baseHPGhostIntro: 1.1,
                baseHPGhostTunnel1: 1.1,
                baseHPGhostTunnel2: 1.035,
                baseHPGhostTunnel3: 1.037,
                baseHPGhostDefault: 1.02,
            },
            baseHPNormalBossFirstTerm: {
                baseHPNormalBossFirstIntro: 20,
                baseHPNormalBossFirstTunnel1: 20,
                baseHPNormalBossFirstTunnel2: 20,
                baseHPNormalBossFirstTunnel3: 20,
                baseHPNormalBossFirstDefault: 20,
            },
            baseHPNormalBossRatio: {
                baseHPNormalBossIntro: 1.1,
                baseHPNormalBossTunnel1: 1.1,
                baseHPNormalBossTunnel2: 1.035,
                baseHPNormalBossTunnel3: 1.037,
                baseHPNormalBossDefault: 1.02,
            },
            baseHPZoneBossFirstTerm: {
                baseHPZoneBossFirstIntro: 1.1,
                baseHPZoneBossFirstTunnel1: 1.1,
                baseHPZoneBossFirstTunnel2: 1.035,
                baseHPZoneBossFirstTunnel3: 1.037,
                baseHPZoneBossFirstDefault: 1.02,
            },
            baseHPZoneBossRatio: {
                baseHPZoneBossIntro: 1.1,
                baseHPZoneBossTunnel1: 1.1,
                baseHPZoneBossTunnel2: 1.035,
                baseHPZoneBossTunnel3: 1.037,
                baseHPZoneBossDefault: 1.02,
            },
        },
        balanceBuilderVersion: 0,
        skillList: [],
        unlockSupportSetting: [],
        questList: [],
        dailyQuestList: [],
        progressTitle: [],
        petList: [],
        achievementList: [],
        heroSkillList: [],
        tutorialTitle: [],
        balanceBuilder: null,
        selectedLevelPreset: null,

        //ItemEditor
        itemList: null,
        itemStatusList: null,

        //Item Impact
        itemImpactBonus: new ItemImpactBonusModel(),
        marketItemWillBonus: new ItemImpactBonusModel(),
        simulatorItemRequireList: [],
        automateItemCreatedData: [],

        commitActions: {
            SAVE_UI: 'saveUi',
            UPDATE_SKILL_LIST: 'updateSkillList',
            UPDATE_QUEST_LIST: 'updateQuestList',
            UPDATE_QUEST_PROGRESS_TITLE: 'updateQuestProgressTitle',
            UPDATE_DAILY_QUEST_LIST: 'updateDailyQuestList',
            UPDATE_ACHIEVEMENT: 'updateAchievement',
            INIT_PROGRESS_TITLE: 'initProgressTitle',
            INIT_PROGRESS_MAIN_TiTLE: 'initProgressMainTitle',
            INIT_TUTORIAL_TITLE: 'initTutorialTitle',
            INIT_PET_LIST: 'initPetList',
            INIT_ACHIEVEMENTS: 'initAchievement',
            INIT_HERO_SKILL: 'initHeroSkill',
            //INIT_SETTINGS_FARMING: 'initFarming',
            // INIT_SETTINGS_FIRST_TERM_GHOST: 'initFirstTermGhost',
            // INIT_SETTINGS_FIRST_TERM_COSTHERO: 'initFirstTermCostHero',
            INIT_RATIOS: 'initRatio',
            //INIT_BALANCE: 'initBalance',
            SAVE_LEVEL_PRESET: 'saveLevelPreset',
            PUSH_ACHIEVEMENTS: 'pushAchievements',
            PUSH_SKILL_SUPPORTS: 'pushSkillSupports',
            PUSH_QUESTS: 'pushQuests',
            PUSH_RATIOS: 'pushRatios',

            //ItemEditor
            INIT_ITEMS: 'initItems',
            ADD_ITEMS: 'addItems',
            INIT_ITEM_STATUS: 'initItemStatus',
            GAMEPLAY_RATIOS: 'gamePlayRatio',
            INIT_SUPPORT_UNLOCK: 'initSupportUnlockSetting',
            PUSH_SUPPORT_UNLOCK_SETTING: 'pushSupportUnlockSetting',
            INIT_GAMEPLAY_SERVICE_DATA: 'initGamePlayDataService',
            UPDATE_GAMEPLAY_SERVICE_DATA: 'updateGamePlayDataService',
            INIT_ITEM_NAME: 'intItemName',
            PUSH_ITEM_NAME: 'pushItemName',

            INIT_UGSSETTING: 'initEnvironmentConfig',
            PUSH_UGSSETTING: 'pushEnvironmentConfig',
            INIT_GAMEVERSION: 'initGameVersionConfig',
            PUSH_GAMEVERSION: 'pushGameVersionConfig',
            INIT_SIMULATOR_ITEM_REQUIRE: "initSimulatorItemRequire",
            PUSH_SIMULATOR_ITEM_REQUIRE: "pushSimulatorItemRequire",
            INIT_AUTOMATE_ITEM_CREATE_DATA: "initAutomateItemCreateData",
            INIT_MULTIPLE_SESSION: "initMultipleSession",
            PUSH_MULTIPLE_SESSION: "pushMultipleSession",
            INIT_ENEMY_DATA: "initEnemyData",
            INIT_CHARACTERS_DATA: "initCharactersData",
            INIT_TEMPLATEPACK: 'initTemplatePack',
            PUSH_TEMPLATEPACK: 'pushTemplatePack',
        }
    },
    mutations: {

        async pushRatios (state, ratios) {
            state.ratios = ratios;
            let config = {
                headers: {Authorization: `Bearer ${this.getters.Token}`}
            };
            let persistedList = await axios.get(NetworkUtils.HOST + "/ratios", config);
            if (persistedList.data && persistedList.data.length > 0) {
                await axios.put(NetworkUtils.HOST + "/ratios/" + persistedList.data[0]._id, {ratios: ratios}, config);
            } else {
                await axios.post(NetworkUtils.HOST + "/ratios/", {ratios: ratios}, config);
            }
        },

        async pushQuests(state, questList) {

            state.questList = questList;
            //la il faut boucler sur toutes les quetes, et les inecter avec une valeur
            //si on a pasla bonne valeur
            // kpiBossLeve and kpiBossKilled
            state.questList.forEach(quest => {
               if (! quest.kpiBossLevel) {
                   quest.kpiBossLevel = 1;
                   quest.kpiBossKilled = 10;
               }
            });

            let config = {
                headers: {Authorization: `Bearer ${this.getters.Token}`}
            };

            let persistedList = await axios.get(NetworkUtils.HOST + "/quests", config);

            if (persistedList.data && persistedList.data.length > 0) {
                //console.log(persistedList.data._id);
                await axios.put(NetworkUtils.HOST + "/quests/" + persistedList.data[0]._id, {list: questList}, config);
                //console.log(res);

            } else {
                await axios.post(NetworkUtils.HOST + "/quests", {list: questList}, config);
                //console.log(res);
            }

        },


        async pushSkillSupports(state, skillList) {

            state.skillList = state.gamePlayDataService.updateSupportSkillSetting(skillList, SupportSkillsConstant);
            let config = {
                headers: {Authorization: `Bearer ${this.getters.Token}`}
            };
            let persistedList = await axios.get(NetworkUtils.HOST + "/skills", config);

            if (persistedList.data && persistedList.data.length > 0) {
                //console.log(persistedList.data._id);
                await axios.put(NetworkUtils.HOST + "/skills/" + persistedList.data[0]._id, {list: skillList}, config);
                //console.log(res);

            } else {
                //await axios.post(NetworkUtils.HOST + "/achievements", {list: skillList});
                await axios.post(NetworkUtils.HOST + "/skills",{list:skillList}, config)
                //console.log(res);
            }

        },

        async pushSupportUnlockSetting(state, unlockSupportSetting) {

            state.unlockSupportSetting = unlockSupportSetting;
            state.gamePlayDataService.updateSupportUnlockSetting(unlockSupportSetting);
            let config = {
                headers: {Authorization: `Bearer ${this.getters.Token}`}
            };
            let persistedList = await axios.get(NetworkUtils.HOST + "/unlock-support-settings", config);

            if (persistedList.data && persistedList.data.length > 0) {
                await axios.put(NetworkUtils.HOST + "/unlock-support-settings/" + persistedList.data[0]._id, {list: unlockSupportSetting}, config);
            } else {
                await axios.post(NetworkUtils.HOST + "/unlock-support-settings",{list:unlockSupportSetting}, config)
            }

        },


        async pushAchievements(state, achievementList) {

            //console.log(achievementList);
            let listToPush = achievementList.map(a => a.getOriginal());
            state.achievementList = listToPush;

            let config = {
                headers: {Authorization: `Bearer ${this.getters.Token}`}
            };

            let persistedList = await axios.get(NetworkUtils.HOST + "/achievements", config);
            //console.log('DEBUG PETLIST');
            //console.log(persistedList.data);

            if (persistedList.data && persistedList.data.length > 0) {
                //console.log(persistedList.data._id);
                await axios.put(NetworkUtils.HOST + "/achievements/" + persistedList.data[0]._id, {list: listToPush}, config);
                //console.log(res);

            } else {
                await axios.post(NetworkUtils.HOST + "/achievements", {list: listToPush}, config);
                //console.log(res);
            }

        },

        saveUi (state,uiObject) {
          state.ui[uiObject.uiScreen] = uiObject.uiValue;
        },
        updateSkillList(state, skillList) {
            state.skillList = state.gamePlayDataService.updateSupportSkillSetting(skillList, SupportSkillsConstant);
        },
        updateQuestList(state, questList) {
            state.questList = questList;
        },
        updateQuestProgressTitle(state, questTitleData) {
            state.progressTitle[questTitleData.progressTitleCode] = questTitleData.progressTitle;
        },
        updateDailyQuestList(state, dailyQuestList){
            state.dailyQuestList = dailyQuestList;
        },
        initProgressTitle(state, progressTitleList) {
            state.progressTitle = progressTitleList;
        },
        initProgressMainTitle(state, progressMainTitleList) {
            state.progressMainTitle = progressMainTitleList;
        },
        initTutorialTitle(state, tutorialTitleList) {
            state.tutorialTitle = tutorialTitleList;
        },
        initPetList(state, petList) {
            state.petList = petList;
        },
        initAchievement(state, list) {
            state.achievementList = list;
        },
        initHeroSkill(state, list) {
            state.heroSkillList = list;
        },

        //Item Editor
        initItems(state, list){
            state.itemList = list;
        },
        addItems(state, list){
            list.forEach(item => {
                //to fix duplicate add item to list
                if(state.itemList.find(it => it.id === item.id) === undefined){
                    state.itemList.push(item);
                }
            });
        },
        initItemStatus(state, list){
            state.itemStatusList = list;
        },

        //initFarming(state, farming) {
        //    state.settings.farmingSize = farming;
        //},

        // initFirstTermCostHero(state, firstTermCostHero) {
        //     state.settings.firstTermCostHero =  firstTermCostHero;
        // },

        // initFirstTermGhost (state, firstTermGhost) {
        //   state.settings.firstTermGhost =  firstTermGhost;
        // },

        initRatio(state, ratios) {

            //to fix undefined value
            if(!ratios.dailyGameLevelPerDay){
                ratios.dailyGameLevelPerDay = 30;
            }

            state.ratios = ratios;
        },

        initBalance(state, levels) {
            //console.log('will try to init balance with levels');
            console.log(levels);
            //let ratios = {};
            //ratios.ratioDropCoins = ratioDropCoins;
            //state.balanceBuilder = new BalanceBuilder(state.skillList, state.achievementList, state.petList, levels, null, null, state.ratios, state.settings);
            //state.balanceBuilderVersion++;
            //console.log('New Balance Builder Version');
            //console.log(state.balanceBuilderVersion);
        },

        gamePlayRatio(state, ratios){
            state.gamePlayRatios = ratios;
        },

        initGamePlayDataService(state, gamePlayRatio){
            state.gamePlayDataService = new GamePlayDataServices(gamePlayRatio, state.supportersData, state.unlockSupportSetting, state.multipleSessionConfig);
        },

        updateGamePlayDataService(state, ratiosValue){
            //convert from ratio editor to ratio like game play
            let ratioLikeGamePlay = GamePlayRatioApdater.getGamePlayRatio(ratiosValue);
            state.gamePlayDataService.updateRatio(ratioLikeGamePlay);
        },

        initSupportUnlockSetting(state, unlockSupportSetting) {
            if(unlockSupportSetting === null || unlockSupportSetting.length === 0 || unlockSupportSetting.length > 16){
                unlockSupportSetting = [];
                state.supportersData.forEach(s => {
                    if(s.m_SupportStandType !== 2){
                        unlockSupportSetting.push({
                            m_iID: s.m_iID,
                            name: s.m_sName,
                            kpiGameLevelShouldUnlock: s.kpiGameLevelShouldUnlock
                        });
                    }
                });
            }
            state.unlockSupportSetting = unlockSupportSetting;
        },

        saveLevelPreset(state, selectedLevelPreset) {
            state.selectedLevelPreset = selectedLevelPreset;
        },

        updateAchievement(state, list) {
            let newList = [];

            state.achievementList.forEach(achievement => {
                let filtered = list.filter(e => e.id === achievement.id);
                let gameplayAchievement = filtered[0];

                // "baseReward": 5,
                //     "maxReward": 10,
                //     "baseGoal": 10,
                //     "goalMultiplier": 300

                achievement.baseReward = gameplayAchievement.baseReward;
                achievement.baseGoal = gameplayAchievement.startLevel;
                achievement.goalMultiplier = gameplayAchievement.jumpingLevel;
                achievement.maxReward = gameplayAchievement.maxReward;

                newList.push(achievement);
            });

            state.achievementList = newList;
        },

        intItemName(state, payload){
            let {itemNames, weaponNames, potionNames} = payload;
            state.itemNameList = itemNames;
            state.itemWeaponNames = weaponNames;
            state.itemPotionNames = potionNames;
            weaponNames.forEach(item => {
                let ref = itemNames?.find(r => r.name === item.name);
                if(ref){
                    item.usage = ref.usage;
                } else {
                    itemNames.push({
                        name: item.name,
                        usage: 0,
                    })
                }
            });
            potionNames.forEach(item => {
                let ref = itemNames?.find(r => r.name === item.name);
                if(ref){
                    item.usage = ref.usage;
                } else {
                    itemNames.push({
                        name: item.name,
                        usage: 0,
                    })
                }
            });
        },

        async pushItemName(state, list) {

            state.itemNameList = list;
            let config = {
                headers: {Authorization: `Bearer ${this.getters.Token}`}
            };
            let persistedList = await axios.get(NetworkUtils.HOST + "/item-name",config);

            if (persistedList.data && persistedList.data.length > 0) {
                await axios.put(NetworkUtils.HOST + "/item-name/" + persistedList.data[0]._id, {list: state.itemNameList},config);

            } else {
                await axios.post(NetworkUtils.HOST + "/item-name", {list: state.itemNameList},config);
            }

        },

        initEnvironmentConfig(state, ugsSetting){
            if(ugsSetting !== null) {
                state.ugsSetting = ugsSetting;
            }
        },

        async pushEnvironmentConfig(state) {
            let config = {
                headers: {Authorization: `Bearer ${this.getters.Token}`}
            };
            let persistedList = await axios.get(NetworkUtils.HOST + "/environment-config",config);

            if (persistedList.data && persistedList.data.length > 0) {
                await axios.put(NetworkUtils.HOST + "/environment-config/" + persistedList.data[0]._id, {list: state.ugsSetting},config);

            } else {
                await axios.post(NetworkUtils.HOST + "/environment-config", {list: state.ugsSetting},config);
            }

        },

        initTemplatePack(state, templatePack){
            if(templatePack !== null) {
                state.templatePack = templatePack;
            }
        },

        async pushTemplatePack(state){
            let config = {
                headers: {Authorization: `Bearer ${this.getters.Token}`}
            };
            let persistedList = await axios.get(NetworkUtils.HOST + "/template-item-pack",config);

            if (persistedList.data && persistedList.data.length > 0) {
                await axios.put(NetworkUtils.HOST + "/template-item-pack/" + persistedList.data[0]._id, {templatePack: state.templatePack},config);

            } else {
                await axios.post(NetworkUtils.HOST + "/template-item-pack", {templatePack: state.templatePack},config);
            }
        },

        initGameVersionConfig(state, gameversion){
            if(gameversion !== null) {
                state.gameversion = gameversion;
            }
        },

        async pushGameVersionConfig(state) {
            let config = {
                headers: {Authorization: `Bearer ${this.getters.Token}`}
            };
            let persistedList = await axios.get(NetworkUtils.HOST + "/gameversion", config);

            if (persistedList.data && persistedList.data.length > 0) {
                await axios.put(NetworkUtils.HOST + "/gameversion/" + persistedList.data[0]._id, {list: state.gameversion}, config);

            } else {
                await axios.post(NetworkUtils.HOST + "/gameversion", {list: state.gameversion},config);
            }

        },

        initSimulatorItemRequire(state, simulatorItemRequireData){
            if(simulatorItemRequireData !== null) {
                state.simulatorItemRequireList = simulatorItemRequireData.list;
                state.simulatorItemRequireConfig = simulatorItemRequireData.config;
            }
        },

        async pushSimulatorItemRequire(state) {
            let config = {
                headers: {Authorization: `Bearer ${this.getters.Token}`}
            };
            let persistedList = await axios.get(NetworkUtils.HOST + "/simulator-item-require", config);

            if (persistedList.data && persistedList.data.length > 0) {
                await axios.put(NetworkUtils.HOST + "/simulator-item-require/" + persistedList.data[0]._id, {list: state.simulatorItemRequireList}, config);

            } else {
                await axios.post(NetworkUtils.HOST + "/simulator-item-require", {list: state.simulatorItemRequireList},config);
            }

        },

        initAutomateItemCreateData(state, automateItemCreatedData){
            if(automateItemCreatedData !== null) {
                state.automateItemCreatedData = automateItemCreatedData.list;
                state.automateCreateItemOptions = automateItemCreatedData.simulatorOptions;
            }
        },

        initMultipleSession(state, multipleSessionConfig){
            if(multipleSessionConfig !== null) {
                state.multipleSessionConfig = multipleSessionConfig;
            }
        },

        async pushMultipleSession(state, multipleSessionConfig){
            state.multipleSessionConfig = multipleSessionConfig;
            let config = {
                headers: {Authorization: `Bearer ${this.getters.Token}`}
            };
            let persistedList = await axios.get(NetworkUtils.HOST + "/multiple-session", config);

            if (persistedList.data && persistedList.data.length > 0) {
                await axios.put(NetworkUtils.HOST + "/multiple-session/" + persistedList.data[0]._id, {config: state.multipleSessionConfig}, config);

            } else {
                await axios.post(NetworkUtils.HOST + "/multiple-session", {config: state.multipleSessionConfig}, config);
            }
        },

        initEnemyData(state, enemysDataPayload){
            if(enemysDataPayload !== null){
                if(enemysDataPayload.enemysData !== null) {
                    state.enemysData = enemysDataPayload.enemysData;
                }
                if(enemysDataPayload.enemysZoneData !== null) {
                    state.enemysZoneData = enemysDataPayload.enemysZoneData;
                }
            }
        },

        initCharactersData(state, charactersData){
            if(charactersData !== null){
                if(charactersData.heroesData !== null){
                    state.heroesData = charactersData.heroesData;
                }
                if(charactersData.supportersData !== null){
                    state.supportersData = charactersData.supportersData;
                }
                if(charactersData.petsData !== null){
                    state.petsData = charactersData.petsData;
                }
            }
        },
    }
})
